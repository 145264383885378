$(document).ready(function () {

    $(document).on('click', '.footer-logo', function(e) {
        e.preventDefault();
        $('#copyrightspopup').fadeToggle('slow');
    });

    // Menu
    $(".icon-menu-large").on("click", function () {
        $(this).toggleClass("menu-active");
        $(".nav-menu").toggle("slow");
    });
    $(".icon-menu-small").on("click", function () {
        var headerinfo = $(".header-info");
        $(this).toggleClass("menu-active");
        $(".nav-menu").slideToggle("slow");
        if (headerinfo.css("display") == "block") {
            headerinfo.hide();
        }

        if (headerinfo.css("margin-right") == "15px") {
            headerinfo.show();
        }
    });
    $(".header-info-heading").on("click", function () {
        $(this).toggleClass("header-info-heading-active");
        $(".nav-menu").hide();
        $(".header-info").slideToggle("slow");
    });

    var owl = $("#owl-references-1");
    var owl_slides = owl.children('div');
    if (owl_slides.length > 1) {
        owl.owlCarousel({
            nav: false, // Show next and prev buttons
            smartSpeed: 300,
            items: 1,
            loop: true,
            dots: false,
            autoplay: true,
            autoplayTimeout: 4000
        });
    }

    var owl2 = $("#owl-references-2");
    var owl2_slides = owl2.children('div');
    if (owl2_slides.length > 1) {
        owl2.owlCarousel({
            nav: false,
            smartSpeed: 300,
            items: 1,
            dots: true,
            loop: true,
            autoplay: true,
            autoplayTimeout: 6000
        });
    }

    // Custom Navigation Events
    $(".next").click(function () {
        owl.trigger('next.owl.carousel');
    });
    $(".prev").click(function () {
        owl.trigger('prev.owl.carousel');
    });


    // Select
    var select2 = $('.f-select');
    select2.select2();
    select2.on("select2:open", function () {
        $(".select2-selection").addClass("blue");
        $(".select2-selection__rendered").addClass("blue");
    });
    select2.on("select2:close", function () {
        $(".select2-selection").removeClass("blue");
        $(".select2-selection__rendered").removeClass("blue");
    });
    if (select2.hasClass("error")) {
        $(".select2-selection").addClass("error");
    }
    $(".required").each(function () {
        $("<a class='required-text' title='Toto pole je povinné'>*</a>").insertBefore(this);
    });

    //Contact Form - Error
    $(".f-submit").click(function () {
        function errorForm(name, text) {
            var xname = $(".f-" + name);
            var zname = xname.val();
            if (zname == null || zname == "") {
                $(".error-text").removeClass("hidden").show();
                $(".error-text-inner").empty().append(text);
                xname.addClass("error");
                //$('html, body').animate({
                //    scrollTop: xname.offset().top
                //}, 10);
                return false;
            }
        }
        $("input").removeClass("error");
        $("select").removeClass("error");
        $(".f-text").removeClass("error");
        $(".error-text").hide();
        errorForm("text", "Nebyl vložen žádný text");
        errorForm("email", "Nebyl vyplněn e-mail");
        errorForm("name", "Nebylo vyplněno jméno");
    });

    if ( $( "#contactForm" ).length ) {
        $( "#contactForm" ).append('<input name="antispam" value="antispam" type="hidden" />');
    }
});
